.i-icon
{
  display: inline-block;
  //width: 24px;
  height: 24px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-lang-en
{
  background-image: data-uri('image/svg+xml;charset=UTF-8', "/static/img/icons/en.svg");
  width: 16px;
  height: 12px;
}


.icon-lang-ru
{
  background-image: data-uri('image/svg+xml;charset=UTF-8', "/static/img/icons/ru.svg");
  width: 16px;
  height: 12px;
}

.icon-lang-zh
{
  background-image: data-uri('image/svg+xml;charset=UTF-8', "/static/img/icons/zh.svg");
  width: 16px;
  height: 12px;
}

.help-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

