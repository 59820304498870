@import "../_extends/vars";

.faq {
  padding-bottom: 4rem;
  &__content {
    margin-top: 0.5rem;
  }

  &__card {
    margin-bottom: 1rem;
    padding: 0 1rem;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    background-color: #fcfcfc;
    box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);
    transition: all ease 0.8s;

    .card-header {
      padding: 0;
    }

    &.active {
      background-color: #ffffff;
      box-shadow: 0 8px 10px 0 rgba(0, 76, 113, 0.1);
      .faq__card-header {
        color: @light-grey;
      }
    }

    &-header {
      .brand-font-medium();
      color: @main-color;
      font-size: 1rem;
      padding: 0.5rem 0;
      margin-bottom: 0;
      display: block;
      transition: color ease-in-out 0.3s;
    }
  }

  .card-header {
    background-color: rgba(255, 255, 255, 0.24) !important;
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
  }

  .card-body {
    border-top: 1px solid #eaeaea;
    padding-left: 0;
    padding-right: 0;

    a {
      .brand-font-medium();
      color: @accent-color;
    }
  }

  &__support {
    position: sticky;
    top: 0;
    padding: 20px 20px 10px 20px;
    min-height: 204px;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    background-color: #fcfcfc;
    box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);

    &-heading {
      .brand-font-medium();
      font-size: 1rem;
    }

    &-text {
      font-size: 14px;
    }

    &-email {
      .brand-font-medium();
      font-size: 1.2rem;
      color: @main-color-light;
      &:hover {
        color: @accent-color;
      }
    }

    &-social {
      margin-top: 1.5rem;
    }
  }

  &__social {
    display: flex;
    justify-content: space-around;
    align-items: center;

    &-item {
      line-height: 1;
      svg {
        height: 18px;
        & > * {
          fill: @accent-color;
          transition: 0.2s all ease-in-out;
        }
        &:hover {
          & > * {
            fill: #0c2c3b;
          }
        }
      }
    }
  }
}

@media screen and (max-width: @desktop) {
  .faq {
    &__support {
      &-email {
        font-size: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: @tablet) {
  .faq {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: @mobile) {
  .faq {
    &__content {
      margin-top: 0;
    }
    &__card {
      &-header {
        font-size: 0.9rem;
      }
    }

    .card-body {
      font-size: 14px;
    }
  }
}
