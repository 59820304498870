@import "../_extends/vars";

.project-section {
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 rgba(144, 144, 144, 0.24);

  &__heading {
    color: @main-color;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -0.15px;
    line-height: 33px;
    padding: 0;
  }
}

.buttons {
  &__next {
    cursor: pointer;
    position: relative;
    padding: 10px 56px 12px 40px;
    margin-bottom: 10px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%) rotateZ(-45deg);
      width: 7px;
      height: 7px;
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
    }
    &:hover {
      &:before {
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
    }
  }

  &__back {
    cursor: pointer;
    position: relative;
    padding: 10px 56px 12px 40px;
    margin-bottom: 10px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 32px;
      transform: translateY(-50%) rotateZ(45deg);
      width: 7px;
      height: 7px;
      border-left: 2px solid @main-color;
      border-bottom: 2px solid @main-color;
    }
    &:hover {
      &:before {
        border-left: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
    }
  }
}

// step 4

.project-step4-section1 {
  background-size: cover;
  background-color: @light-grey;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  padding: 2rem;
  &__name {
    color: #ffffff;
    font-size: 48px;
    font-weight: 800;
    letter-spacing: -0.4px;
    line-height: 72px;
    border: none;
    outline: none;
    background-color: @light-grey;
    border-radius: 4px;
    margin: 0 0 48px;
    width: 100%;
    height: 72px;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ffffff;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #ffffff;
    }
  }
  &__background {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 24px;
    margin: 0;
  }
  &__button {
    margin-top: 4px;
    display: inline-flex;
    align-items: center;
    background-color: @light-grey;
  }
  &__add-img {
    margin-right: 8px;
    opacity: 0.8;
  }
  &__container {
    margin-top: 220px;
    margin-bottom: 120px;
  }
}

.project-step4-section2 {
  padding-bottom: 80px;

  &__heading {
    margin: 56px 0 24px;
  }

  .note-frame {
    padding-left: 1px;
  }
}

.whitepaper {
  padding-bottom: 67px;

  &__heading {
    margin: 40px 0;
  }

  &__img {
    margin-right: 32px;
  }

  &__upload {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 10px;
  }

  &__filename {
    opacity: 0.8;
    color: @main-color;
    font-size: 16px;
    line-height: 24px;

    &--uploaded {
      font-weight: 600;
      line-height: 22px;
      opacity: 1;
    }
  }
}

.team-members-section {
  padding-bottom: 58px;
  &__heading {
    margin: 40px 0 32px;
  }
}

.team-members-list {
  display: flex;
  gap: 1.5rem;
}

.add-team-member-button {
  width: 160px;
  height: 160px;
  border: 2px solid @main-color;
  border-radius: 2px;
  background-color: #ffffff;
  position: relative;

  &:before {
    content: "";
    background-image: url("/static/img/icons/add.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 46.67px;
    height: 46.67px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.team-member-block {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 2px;
  margin-bottom: 12px;

  &__input {
    border: none;
    outline: none;
    width: 160px;
    color: @main-color;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 22px;
    margin-bottom: 12px;
  }
}

.delete-team-member-button {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: -1px;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-bottom-left-radius: 20%;

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
}

.social-section {
  background-color: #ffffff;

  &__heading {
    margin-top: 35px;
  }
  &__container {
  }
  &__item {
    padding: 40px 0;
  }
  &__name {
    color: @grey-color;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 17px;
    text-transform: uppercase;
  }
  &__input {
    border: 1px solid rgba(144, 144, 144, 0.24);
    border-radius: 4px;
    background-color: #ffffff;
    color: @main-color;
    &:hover,
    &:focus,
    &:active,
    &:disabled,
    &[readonly] {
      border: 1px solid rgba(144, 144, 144, 0.24);
      border-radius: 4px;
      background-color: #ffffff;
      color: @main-color;
      box-shadow: none;
    }
  }
}

.step6-buttons {
  margin: 24px 0 80px;
}

.step5 {
  &__container {
    margin-top: 2rem;
  }
}

.step4 {
  &__heading {
    color: @main-color;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: -0.2px;
    line-height: 33px;
    margin: 40px 0;
  }

  &-buttons {
    margin: 40px 0;
    gap: 1rem;

    &__add {
      margin: 40px 0;
    }
  }
}

.product {
  &-upload {
    width: 155px;
    margin-top: 10px;
  }
  &-input,
  &-input:hover,
  &-input:focus,
  &-input:active {
    box-sizing: border-box;
    height: 41px;
    width: 100%;
    border: 1px solid rgba(144, 144, 144, 0.24);
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    background-color: #ffffff;
    color: @main-color;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 22px;
    padding-left: 12px;
    margin-bottom: 9px;
  }

  &-textarea,
  &-textarea:hover,
  &-textarea:focus,
  &-textarea:active {
    height: 100px;
    resize: none;
    padding-top: 10px;
  }

  &-delete {
    &__button {
      cursor: pointer;
      height: 44px;
      width: 44px;
      border: 1.5px solid #f2979d;
      border-radius: 2px;
      background-color: #ffffff;
      position: relative;
    }
    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.localization-tab {
  margin-right: 10px;
  margin-bottom: 12px;
  &:hover {
    .localization-tab__icon {
      opacity: 1;
    }
  }
  &__button {
    border: none;
    background: none;
    font-size: 14px;
    text-transform: uppercase;
    color: @grey-color;
    letter-spacing: 0.5px;
    padding: 10px 10px 15px;
    margin-left: 1rem;
    &.active {
      border-bottom: 2px solid @accent-color;
      color: @main-color;
      font-weight: 600;
    }
    &:hover {
      color: @main-color;
    }
    &-icon {
      opacity: 0.6;
      padding-right: 5px;
    }
  }
  &__icon {
    background: url("/static/img/close-icon.svg") no-repeat;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 0;
    margin-bottom: 8px;
    display: inline-block;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.5s;
  }
  &__add {
    box-shadow: 0 5px 15px 0 rgba(93, 97, 104, 0.05), 0 -4px 65px 0 rgba(23, 72, 195, 0.09);
    border: none;
    width: 100%;
    --bs-dropdown-item-padding-y: 0.7rem;
    li a {
      &:hover {
        color: @accent-color;
      }
    }
  }
}

// step 4
.finish-alert {
  width: 100%;
  padding: 24px 32px;
  background-color: rgba(231, 173, 58, 0.09);
  box-shadow: inset 3px 0 0 0 #e7b73a;
  margin-top: 24px;

  &__text {
    color: @main-color;
    font-size: 16px;
    line-height: 24px;

    &:nth-child(odd) {
      margin-bottom: 1.5rem;
    }

    &-curve {
      font-style: italic;
    }
  }
}

// success

.create-project-success {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid #e0e9ee;
  background-color: #ffffff;
  &__container {
    text-align: center;
  }
  &__image {
    margin-top: 20px;
  }
  &__head {
    .brand-font-normal();
    color: @main-color-light;
    font-size: 25px;
    line-height: 40px;
  }
  &__text {
    color: #0c3244;
    font-size: 18px;
    letter-spacing: -0.45px;
    line-height: 2em;
    margin: 16px 0 57px;
  }
  &__button {
    margin-bottom: 100px;
    display: inline-block;
  }
}

////////////////////////////////

.upload-btn {
  text-transform: uppercase;
  border: 2px solid @accent-color;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 30px;
  background: transparent;
  color: #ffffff;
  &-plus {
    font-size: 16px;
    color: @accent-color;
  }
}

////////////////////////////

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/////////////////////
.complete_section .complete {
  background-color: white;
  color: @main-color;

  .almost-done {
    text-align: center;
    h3 {
      color: @main-color;
      font-size: 32px;
      line-height: 48px;
    }

    p {
      color: @main-color;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: lighter;
    }
  }

  .create-contract-steps {
    padding-bottom: 50px;

    .row {
      margin-bottom: 20px;
    }

    .marker {
      display: inline-block;
      height: 80px;
      width: 80px;
      background-color: #ececec;
      color: @main-color;
      font-size: 32px;
      font-weight: 800;
      line-height: 80px;
      text-align: center;
      border-radius: 50%;
      padding: 0;
      max-width: 80px;
      margin-right: 18px;
    }

    h3 {
      color: @main-color;
      font-size: 32px;
    }

    p {
      color: @main-color;
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      font-weight: lighter;
    }

    .success-text {
      color: @accent-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      margin: 24px 0 40px;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .list-disabled {
      .marker,
      h3 {
        opacity: 0.4;
      }

      p,
      .buttons-container {
        display: none;
      }
    }
  }
  .address-input {
    border: 1px solid rgba(144, 144, 144, 0.24);
    border-radius: 4px;
    background-color: #ffffff;
    color: @main-color;
    &:hover,
    &:focus,
    &:active,
    &:disabled {
      border: 1px solid rgba(144, 144, 144, 0.24);
      border-radius: 4px;
      background-color: #ffffff;
      color: @main-color;
    }
  }

  .buttons-container {
    a {
      display: inline-block;
    }
  }
}

.popover {
  .arrow {
    border: none;
    padding: 0;
  }
}

/////////////////////////////

.note-popover.popover {
  display: none;
}

.form-group.note-group-image-url {
  display: none;
}

.modal-header {
  flex-direction: row-reverse;
}

.note-form-label {
  display: block;
}

.note-image-input {
  color: #000000;
  height: 30px;
}

.note-toolbar {
  z-index: 1 !important;
}

@media (max-width: @laptop) {
  .project-step4-section1 {
    &__name {
      font-size: 28px;
    }
    &__container {
      margin: 80px 0 40px;
    }
  }
}

@media (max-width: @mobile) {
  .project-step4-section1 {
    &__name {
      .brand-font-medium();
      font-size: 18px;
    }
    &__container {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  .project-section__heading {
    margin-top: 30px;
    .brand-font-medium();
  }

  .social-section__container {
    margin: auto;
  }

  .social-section__item {
    padding: 20px 0;
  }

  .social-section__name {
    .brand-font-medium();
    margin-bottom: 5px;
  }

  .team-members-list {
    flex-direction: column;
    &-container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .add-team-member-button {
    width: 120px;
    height: 120px;
  }

  .step4-buttons {
    margin: 24px 0;
    flex-direction: column;
    align-items: stretch;
  }

  .product-input {
    font-size: 14px;
    &:hover,
    &:focus {
      font-size: 14px;
    }
  }

  .product-upload {
    margin-bottom: 10px;
  }

  .upload-photo-btn--size-sm {
    margin-bottom: 10px;
  }

  .product-delete__button {
    margin-bottom: 10px;
  }

  .complete_section .complete .create-contract-steps {
    h3 {
      font-size: 20px;
      font-weight: 600;
    }

    .marker {
      width: 50px;
      height: 50px;
      font-size: 24px;
      line-height: 50px;
      margin-right: 0;
    }

    p {
      font-size: 14px;
    }

    .success-text {
      margin-top: 0;
    }

    .address-input {
      font-size: 12px;
    }
  }

  .abi-block {
    margin-bottom: 20px;
  }

  .create-project-success__button {
    margin-bottom: 30px;
  }

  .buttons {
    &__back {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .finish-alert {
    padding: 16px 20px;

    &__text {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .localization-tab {
    &__button {
      font-size: 12px;
    }
    &__add {
      --bs-dropdown-item-padding-y: 0.3rem;
      font-size: 14px;
    }
  }
}
