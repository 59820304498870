@import '../_extends/vars';

//Заготовки классов, дл использования вместо inline style тегов (чтобы проще было с СSP)
.display-inline-important {
  display: inline! important;
}

.display-inline-block {
  display: inline-block;
}

.hidden {
  visibility: hidden;
}

.zero-border {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.width-100-percent {
  width: 100%
}

.approve-style {
  display: none;
  background-color: rgba(204,255,231,0.68);
}

.warn-style {
  display: none;
  background-color: rgba(255, 231, 128, 0.68);
}

.margin-0-px {
  margin: 0;
}

.margin-0-px-auto {
  margin: 0 auto;
}

.margin-top-20-px {
  margin-top: 20px;
}

.margin-top-40-px {
  margin-top: 40px;
}
