@import "../_extends/vars";

.project {
  &__item {
    margin-bottom: 1.5rem;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    background-color: #FCFCFC;
    box-shadow: 0 8px 10px 0 rgba(224,233,238,0.21);
    position: relative;

    &-content {
      padding: 1rem 1rem 1.5rem;
    }
    &-date {
      font-size: 14px;
      color: @main-color;
      margin-top: 0.7rem;
      margin-bottom: 4px;
    }

    &-title {
      .brand-font-medium();
      font-size: 16px;
      text-transform: uppercase;
      color: @main-color;
      margin-top: 1rem;
    }

    &-img {
      height: 300px;
      width: 100%;
      border-radius: 2px;
      position: relative;
    }
    &-erc20 {
      display: flex;
      align-items: center;
      position: absolute;
      top: 1rem;
      left: 2rem;
      border-radius: 4px;
      background-color: #E0E9EE;
      color: @main-color-light;
      text-transform: uppercase;
      .brand-font-medium();
      font-size: 12px;
      padding: 3px 1rem;

      &-check {
        margin-left: 5px;
        background: url("/static/img/icons/checked.svg") no-repeat;
        background-size: 10px;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
  }
}

@media screen and (max-width: @tablet) {
  .project {
    &__item {
      &-img {
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: @mobile) {
  .project {
    &__item {
      &-img {
        height: 175px;
      }
      &-title {
        font-size: 15px;
      }
      &-erc20 {
        transform: scale(.8);
        left: 1.5rem;
      }
    }
  }
}
