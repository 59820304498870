@import "../_extends/vars";

.how {
  &__heading {
    margin-bottom: 2rem;
    text-align: center;
  }
  p {
    margin-bottom: 2rem;
    img {
      max-width: 80%;
    }
  }

  &__step {
    flex-direction: column;
    align-items: flex-start;

    &-heading {
      color: @main-color;
      font-size: 20px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 10px;
      display: block;
    }

    &-text {
      color: @main-color;
      font-size: 16px;
      line-height: 24px;
      display: block;
    }
  }

  &__video {
    margin: 80px 0;
    max-width: 960px;
    height: 500px;
  }

  &__description {
    color: #323439;
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 0;
    text-align: justify;
  }

  &__switch {
    border-radius: 4px;
    border: 2px solid #e0e9ee;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    &-container {
      justify-content: center;
    }
    &-item {
      width: 100%;
      padding: 8px 0;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: -0.2px;
      line-height: 24px;
      color: @main-color-light;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
      word-wrap: break-word;
      &:hover {
        background-color: #e0e9ee;
        color: @main-color;
      }
      &--active {
        font-weight: 400;
        background-color: #e0e9ee;
      }
    }
  }

  &__action-btn {
    display: block;
    margin-top: 1.5rem;
  }
}

.smart-contracts {
  padding-top: 6rem;
  padding-bottom: 2rem;
  &__heading {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .table-contracts {
    margin-bottom: 40px;
    &__limit {
      font-weight: bold;
      text-transform: uppercase;
      color: @grey-color;
      font-size: 14px;
      vertical-align: middle;
    }
    &__desc {
      color: @main-color;
      font-weight: 600;
      letter-spacing: -0.1px;
    }
    &__yes-no {
      color: @accent-color;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.8px;
      line-height: 24px;
      text-transform: uppercase;
    }
    &__remark {
      color: @grey-color;
      font-size: 12px;
      line-height: 18px;
    }
    & > thead {
      td {
        border-top: none;
        font-size: 20px;
        font-weight: bold;
        line-height: 55px;
        height: 81px;
        padding-left: 17.5px;
        letter-spacing: -0.2px;
        &:not(:first-child) {
          background-color: #f5f5f5;
          border: 2px solid #e6e6e6;
        }
      }
    }

    & > tbody > tr > td {
      border: 2px solid #e6e6e6;
      background-color: #fafafa;
      width: 20%;
      padding: 13px;
    }
  }

  &__content {
    p {
      margin-bottom: 1.5rem;
    }
    h5,
    span {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: @mobile) {
  .how {
    &__video {
      margin: 20px 0;
      max-height: 300px;
    }

    &__step {
      &-text {
        font-size: 14px;
        line-height: 20px;
        & + ol, li {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
    }

    &__switch {
      &-item {
        font-size: 10px;
      }
    }
  }
}
