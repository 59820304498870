@import "../_extends/vars";

.progress {
  position: relative;
  overflow: visible;
  height: 12px;
  border-radius: 0;

  .progress-bar {
    background-color: @accent-color;
    height: 12px;
  }

  .progress-mark {
    position: absolute;
    float: left;
    width: 0;
    height: 100%;
    line-height: 20px;
    text-align: center;
    background-color: transparent;
    border-right: 2px solid @light-blue-color;
    z-index: 100;

    .progress-mark-title {
      position: absolute;
      display: inline-block;
      text-transform: lowercase;
      top: 14px;
      right: -150px;
      width: 200px;
      text-align: left;
      color: @light-grey;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .progress-bar.bg-success {
    background-color: #28a745;
  }

}

@media screen and (max-width: @tablet) {
  .progress {
    .progress-mark {
      .progress-mark-title {
        right: -160px;
      }
    }
  }
}

@media screen and (max-width: @mobile) {
  .progress {
    .progress-mark {
      .progress-mark-title {
        right: -200px;
      }
    }
  }
}
