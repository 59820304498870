@import "../_extends/vars";

.account {
  &__heading {
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
    color: @main-color;
  }

  &__tabs {
    display: flex;
    justify-content: flex-end;
    &-item {
      text-transform: uppercase;
      color: @grey-color;
      letter-spacing: .5px;
      font-size: 14px;
      padding: 15px 10px;
      &:hover {
        color: @accent-color;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
      &--active {
        border-bottom: 2px solid @accent-color;
        color: @main-color;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: @tablet) {
  .account__tabs {
    justify-content: flex-start;
    &-item {
      font-size: 10px;
    }
  }

  .account__heading {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 600;
  }
}

@media (max-width: @mobile) {
  .note {
    padding: 20px 30px 20px 20px;
    &__content {
      font-size: 14px;
    }
  }

  .account__tabs {
    flex-direction: column;
    &-item {
      font-size: 12px;
    }
  }
}
