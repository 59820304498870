@import "../_extends/vars";

input {
  outline: none;
}

select {
  outline: none;
  height: 41px;
}

label {
  line-height: 30px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"] {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

select {
  -moz-appearance: none;
}

.button {
  border: 2px solid @main-color-light;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  color: @main-color-light;
  font-size: 12px;
  font-weight: 600;
  padding: 12px 20px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0.2px;
  text-align: center;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: @accent-color;
    border-color: @accent-color;
    color: #ffffff;
    transform: translateY(-2px);

    svg {
      fill: #ffffff;
    }
    a {
      color: #ffffff;
    }
  }

  a {
    transition: all 0.2s ease-in-out;
  }

  svg {
    transition: fill 0.2s ease-in-out;
  }

  &--no-border {
    border-color: transparent;
  }

  &--primary {
    color: #ffffff;
    background-color: @accent-color;
    border: 2px solid @accent-color;
    &:hover {
      color: @accent-color;
      background-color: #ffffff;
    }
    &:active {
      background-color: @accent-color-light;
    }
  }
  &--primary-darken {
    background-color: @accent-color;
    &:hover {
      background-color: lighten(@accent-color, 5%);
    }
  }
  &--dark {
    background-color: @main-color;
    max-width: 300px;
    &:hover {
      color: #ffffff;
      background-color: lighten(@main-color, 7%);
    }
  }
  &--large {
    max-width: 300px;
    width: 100%;
  }
  &--cancel {
    color: @grey-color;
    background: transparent;
  }
}

.main-btn {
  display: inline-block;
  position: relative;
  border-radius: 2px;
  background-color: @accent-color;
  box-shadow: inset 0 -1px 0 0 @accent-color-light;
  color: #ffffff;
  font-weight: 600;
  line-height: 24px;
  padding: 8px 16px;
  border: none;
  font-size: 16px;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    color: @accent-color;
    background-color: @accent-color-light;
  }

  &:active {
    box-shadow: 0 -1px 0 0 @light-blue-color;
  }

  &:disabled {
    background-color: @light-blue-color;
  }
}

.upload-photo-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  border: 2px solid rgba(40, 150, 201, 0.24);
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 32px;
  color: @accent-color;

  &--size-sm {
    width: 65px;
    height: 65px;
    font-size: 16px;
  }

  &.loaded-image {
    .glyphicon {
      display: none;
    }
  }
}

.checkbox-custom,
.radio-custom {
  width: 20px;
  height: 20px;
  border: 2px solid @accent-color;
  border-radius: 3px;
  position: relative;
  display: inline-block;
}

.radio-custom {
  border-radius: 50%;
}

.label {
  color: @main-color;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  vertical-align: top;
}

.checkbox:checked + .checkbox-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid @accent-color;
  background: @accent-color;
  border-radius: 50%;
}

.radio-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  border: 5px solid @accent-color;
  background: @accent-color;
  border-radius: 50%;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
}

.radio:checked + .radio-custom::before {
  transform: translate(-50%, -50%) scale(1, 1);
}

.datepicker {
  background: url("/static/img/icons/calendar.svg") no-repeat 96% 50%;
}

.air-datepicker {
  --adp-cell-background-color-selected-hover: @accent-color;
  --adp-cell-background-color-selected: @accent-color;
}
