@import "../_extends/vars";

.error {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;
  }

  &__heading {
    .brand-font-medium();
    text-align: center;
    margin: 0;
    color: @main-color-light;
    font-size: 32px;
    line-height: 48px;
  }

  &__text {
    margin-top: 10px;
    color: @main-color;
    font-size: 16px;
    line-height: 24px;
  }

  &__link {
    text-decoration: none;
    color: @main-color-light;
    .brand-font-medium();
    .base-transition();
    &--underlined {
      text-decoration: underline;
      text-transform: uppercase;
      margin: 45px 0 41px;
    }
    &:hover {
      color: @accent-color;
    }
  }

  &__block {
    margin: 40px 0 70px;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    background-color: #FCFCFC;
    box-shadow: 0 8px 10px 0 rgba(224,233,238,0.21);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 465px;
    width: 100%;
  }

  &__image {
    height: 182px;
    width: 199px;
    margin: 42px 0 0;
    object-fit: cover;
  }
}

@media screen and (max-width: @mobile) {
  .error__container {
    margin: 0 7%;
  }
}
