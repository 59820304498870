.loading-background-image
{
  background-image: url("/static/img/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &-fail
  {
    background-image: url("/static/img/fail.svg");

  }
}