
.share {
  list-style: none;
  padding-left: 0;
  display: flex;
  &__item {
    padding-right: 40px;
  }
}

@media screen and (max-width: @mobile) {
  .share {
    &__item {
      padding-right: 20px;
    }
  }
}
