
@main-color: #003A57;
@main-color-light: #004C71;

@dark-color: #323439;
@grey-color: #909090;
@grey-faded: #E4E4E4;

@dark-blue-color: #1748C3;
@light-blue-color: #DAE2F6;

@accent-color: #00B8C0;
@accent-color-light: #E8F7F7;

@light-grey: #99B8C7;

@mobile: 480px;
@mobile-landscape: 575px;
@tablet: 768px;
@laptop: 992px;
@desktop: 1199px;

.brand-font-normal () {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

.brand-font-medium () {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.base-transition () {
  transition: all .2s ease-in-out;
}

