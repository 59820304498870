.jconfirm {
  z-index: 88; // wallet connect modal has z-index=89
  .jconfirm-bg {
    background-color: rgba(0, 0, 0, 0.51);
  }
  .jconfirm-box {

    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.5);

    &.green {
      &:before {
      }
      &:after {
      }
    }
    div.jconfirm-closeIcon {
    }
    div.jconfirm-title-c {
    }
    div.jconfirm-content-pane {
    }
    div.jconfirm-content {
      &:empty {
      }
    }
    .jconfirm-buttons {

      .main-btn
      {
        .main-btn();
      }

      text-align: right;
      button {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
      }

      button + button {
      }
    }
    &.hilight {
    }
  }
}
