@import "../_extends/vars";

.projects {
  margin-top: 30px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .page__heading {
      margin-bottom: 0;
    }
  }
  &__form {
    display: flex;
    &-select {
      position: relative;
      .brand-font-medium();
      border: 1px solid #EBF1F4;
      border-radius: 4px;
      margin-left: 1rem;
      font-size: 14px;
      color: @main-color-light;
      -webkit-appearance: none;
      padding-left: 10px;
      padding-right: 5px;
      min-width: 200px;
      background: url('/static/img/icons/chevron-down.svg') no-repeat;
      background-size: 20px;
      background-position: 97% 10px;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      .brand-font-medium();
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: #99B8C7;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      .brand-font-medium();
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: #99B8C7;
    }
    :-ms-input-placeholder { /* IE 10+ */
      .brand-font-medium();
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: #99B8C7;
    }
  }

}

.search {
  position: relative;

  &__icon {
    background-image: url("/static/img/icons/search.svg");
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 17px;
  }

  &__input {
    border: 1px solid #EBF1F4;
    border-radius: 4px;
    padding: 5px 5px 5px 35px;
    color: @main-color;
    min-width: 270px;
  }
}

@media screen and (max-width: @laptop) {
  .search {
    margin-top: 1rem;
  }
}

@media screen and (max-width: @mobile){
  .projects {
    margin-top: 15px;
    &__header {
      flex-direction: column;
      align-items: stretch;
    }
    &__form {
      flex-direction: column;
      &-select {
        width: 100%;
        margin-bottom: 1.3rem;
        margin-left: 0;
      }
    }
  }

  .search {
    margin-bottom: 1rem;

    &__input {
      width: 100%;
    }
  }
}
