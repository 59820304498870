@import "../_extends/vars";

.navi {
  background-color: transparent;
  padding-top: 32px;
  padding-bottom: 10px;
  margin-top: 10px;

  &__container {
    border: 1px solid rgba(144,144,144, 0.24);
    display: flex;
    border-radius: 4px;
  }

  &__item {
    color: @grey-color;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 17px;
    text-align: center;
    padding: 15px 10px;
    width: 100%;
    background-color: @grey-faded;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &-mark {
      position: relative;
      height: 18px;
      width: 18px;
      min-width: 18px;
      border-radius: 50%;
      border: 2px solid @accent-color;
      visibility: hidden;
    }
    &.active {
      color: @main-color;
      background-color: rgba(40,150,201,0.09);
      .navi__item-mark {
        visibility: visible;
      }
    }
    &.passed {
      color: @accent-color;
      background-color: #FFFFFF;

      .navi__item-mark {
        visibility: visible;
        &:after {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotateZ(45deg);
          height: 8px;
          width: 4px;
          border-right: 2px solid @accent-color;
          border-bottom: 2px solid @accent-color;
          content: '';
        }
      }
    }
  }
}

@media (max-width: @laptop) {
  .navi {
    &__container {
      flex-direction: column;
    }
    &__item {
      text-align: left;
    }
  }
}

@media (max-width: @mobile) {
  .navi {
    margin-top: 0;
    padding-top: 10px;

    &__item {
      font-weight: 600;
      justify-content: flex-start;
      padding: 10px;
    }
  }
}
