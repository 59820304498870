@import "../_extends/vars";

.landing {
  padding-top: 10rem;
  padding-bottom: 2.5rem;
  &__link {
    text-decoration: none;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 24px;
    color: @main-color;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.2s all ease-in-out;
    &:hover {
      color: lighten(@main-color, 20%);
    }
  }
  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: center;
    margin-bottom: 8rem;
    &-testnet {
      color: @main-color;
      font-family: Courier, serif;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 4px;
      line-height: 14px;
      text-align: center;
      border-radius: 4px;
      background-color: @light-blue-color;
      padding: 3px 12px;
    }
    &-heading {
      .brand-font-medium();
      font-size: 48px;
      letter-spacing: -1px;
      line-height: 56px;
      color: @main-color;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    &-description {
      font-size: 16px;
      line-height: 24px;
      color: @main-color;
      margin-bottom: 36px;
      max-width: 450px;
    }
    &-buttons {
      display: flex;
      align-items: center;
    }
    &-link {
      margin-left: 1rem;
    }
  }

  &__partner {
    &-text {
      text-transform: uppercase;
      letter-spacing: 1.6px;
      font-size: 10px;
      margin-bottom: 1rem;
      padding-left: 7px;
    }
    &-rakib {
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: translateY(-2px);
      }
    }
    &-icon {
      background: url("/static/img/logo-rakib.png") no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
    }
    &-name {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-left: 10px;
      color: @main-color;
    }
  }

  &__today {
    position: relative;
    justify-content: center;
    &-content {
      text-align: center;
      margin-top: 5rem;
    }
    &-img {
      margin: 0 0 35px;
    }
    &-heading {
      .brand-font-medium();
      color: @main-color-light;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 32px;
      margin-bottom: 14px;
    }
    &-text {
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 24px;
      color: @main-color;
      margin-bottom: 35px;
    }
  }

  &__create-card {
    margin-bottom: 1rem;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: #fcfcfc;
    box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);
  }

  &__create-cards {
    &--mobile {
      display: none;
    }
  }

  &__help {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    padding: 24px 16px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);

    &--before-load {
      box-shadow:
        0 25px 15px 0 rgba(93, 97, 104, 0.05),
        0 16px 65px 0 rgba(23, 72, 195, 0.29);
    }
    &--loaded {
      animation-name: after-scroll-blink;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-timing-function: ease-in;
    }

    &-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      .brand-font-medium();
    }
    &-text {
      font-size: 14px;
      letter-spacing: -0.4px;
      line-height: 24px;
      color: @main-color;
      margin-bottom: 24px;
      &-container {
        width: 80%;
      }
    }
    &-icon {
      &-container {
        width: 80px;
        height: 80px;
        position: relative;
      }
    }
    &-link {
      display: block;
      transition: 0.2s all ease-in-out;
      color: @main-color;
    }

    &-variants {
      width: 100%;
      display: inline-grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between;
    }

    &-item {
      display: flex;
      padding: 10px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: @accent-color-light;
        border-radius: 4px;
      }

      &-content {
        margin-left: 10px;
      }
      &-title {
        font-size: 12px;
        .brand-font-medium();
        color: @main-color-light;
        margin-bottom: 4px;
      }
      &-text {
        font-size: 12px;
      }
      &-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        &--chat {
          background-image: url("/static/img/landing/chat.svg");
        }
        &--phone {
          background-image: url("/static/img/landing/phone.svg");
        }
        &--faq {
          background-image: url("/static/img/landing/faq.svg");
        }
        &--telegram {
          background-image: url("/static/img/landing/telegram-landing.svg");
        }
      }
    }
  }

  &__benefits {
    position: relative;
    margin-top: 6rem;
    &-heading {
      .brand-font-medium();
      color: @main-color-light;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 32px;
      margin-bottom: 14px;
      text-align: center;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px;
      margin: 1rem 0;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      background-color: #fafafa;
      box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);
      min-height: calc(100% - 2rem);
      &-heading {
        .brand-font-medium();
        text-transform: uppercase;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
        text-align: center;
      }
      &-link {
        display: none; // display when info will be ready
        .brand-font-medium();
        color: @main-color-light;
        font-size: 12px;
        text-decoration: underline;
        &:hover {
          color: @accent-color;
        }
      }
    }
    &-image {
      margin-bottom: 32px;
    }
    &-text {
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 24px;
      color: @main-color;
      text-align: center;
    }
  }

  &__how {
    justify-content: center;
    position: relative;
    margin-bottom: 4rem;
    &-heading {
      .brand-font-medium();
      color: @main-color-light;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 32px;
      margin: 77px 0 44px;
    }
    &-switch {
      border-radius: 4px;
      border: 2px solid #e0e9ee;
      padding: 0;
      margin-bottom: 55px;
      display: flex;
      flex-wrap: nowrap;
      &-item {
        width: 50%;
        display: inline-block;
        padding: 8px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: -0.2px;
        line-height: 24px;
        color: @main-color-light;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
        word-break: break-all;
        &:hover {
          background-color: #e0e9ee;
          color: @main-color;
        }
        &--active {
          font-weight: 400;
          background-color: #e0e9ee;
        }
      }
    }
    &-items {
      &-create {
        width: 100%;
        transform: translateX(-110%);
        transition: 0.3s transform ease-in-out;
      }
      &-invest {
        width: 100%;
        transform: translateX(110%);
        transition: 0.3s transform ease-in-out;
        display: none;
      }
      &--active {
        transform: translateX(0);
      }
      &-row {
        justify-content: space-around;
        align-items: stretch;
      }
      &-grid {
        display: inline-grid;
        gap: 1rem;
        grid-template-columns: repeat(5, 1fr);
      }
      &--mobile {
        display: none;
      }
    }
    &-item {
      border: 1px solid #eaeaea;
      border-radius: 4px;
      background-color: #fcfcfc;
      box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 0 30px;
      &-heading {
        .brand-font-medium();
        letter-spacing: 0.2px;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 24px;
        color: @main-color-light;
        margin-bottom: 12px;
      }
      &-create {
        padding: 24px 8px;
      }
      &-invest {
        padding: 24px 13px;
        flex: 0 0 23%;
      }
    }
    &-step {
      border-radius: 4px;
      background-color: #e8f7f7;
      font-size: 12px;
      .brand-font-medium();
      text-transform: uppercase;
      line-height: 24px;
      color: #00b8c0;
      padding: 0 17px;
      margin-bottom: 1rem;

      &--circle {
        border-radius: 50%;
        margin-bottom: 0;
        padding: 6px 9px;
        line-height: 12px;
        margin-right: 1rem;
      }
    }
    &-text {
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 24px;
      text-align: center;
      color: @main-color;
      margin: 0;
    }
    &-button {
      margin: 95px 0 0;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.1px;
      line-height: 24px;
      color: @main-color;
      background-color: @light-blue-color;
      padding: 10px 40px;
      border-radius: 4px;
      &:hover {
        color: @dark-blue-color;
      }
    }
    &-bottom {
      margin-top: 27px;
      margin-bottom: 30px;
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 24px;
      text-align: center;
      color: @main-color-light;
      &-link {
        .brand-font-medium();
        color: @main-color-light;
        text-decoration: underline;
        transition: 0.2s all ease-in-out;
        &:hover {
          color: #00b8c0;
        }
      }
    }
  }
}

@media (max-width: @laptop) {
  .landing {
    &__top {
      &-heading {
        font-size: 40px;
      }
    }
    &__head-image {
      width: 100%;
    }
  }

  .accordion {
    width: 100%;
  }
}

@media (max-width: @laptop) {
  .landing {
    padding-top: 4rem;
    &__top {
      margin-bottom: 3rem;
      &-heading {
        font-size: 36px;
      }
    }
    &__top {
      &-link {
        margin: 10px 0;
      }
    }
    &__help {
      padding: 16px;
      &-icon {
        height: calc(72px - 5%);
        width: calc(72px - 5%);
        object-fit: cover;
        &-container {
          width: 50px;
          height: 50px;
        }
      }
      &-link {
        font-size: 14px;
        &:nth-child(3) {
          margin-left: 0;
        }
      }
      &-variants {
        grid-template-columns: none;
        grid-template-rows: 1fr;
      }
    }
    &__how {
      &-items-grid {
        grid-template-columns: 1fr 1fr 1fr;
      }
      &-item {
        &-invest {
          flex: 0 0 45%;
        }
      }
    }
  }

  .accordion {
    .landing__how-item-heading {
      margin-bottom: 0;
    }
    .card-header {
      border-bottom-color: rgba(0, 0, 0, 0.03);
      position: relative;
      &__icon {
        position: absolute;
        right: 10px;
        content: "";
        background: url("/static/img/icons/chevron-down.svg") no-repeat;
        background-size: cover;
        display: block;
        width: 24px;
        height: 24px;
        transition: all 0.2s ease-in-out;
      }
    }
    .card-body {
      padding: 0;
    }
  }
}

@media (max-width: @tablet) {
  .landing {
    &__top {
      margin-bottom: 2rem;
      &-heading {
        font-size: 30px;
      }
      &-link {
        margin: 0 36px;
      }
    }
    &__how {
      &-items {
        &-row {
          justify-content: space-between;
        }
        &-grid {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    &__create-cards {
      display: none;

      &--mobile {
        display: block;

        & .card-list__item-heading {
          margin-bottom: 0;
        }
      }

      &-item {
        max-width: 100%;
        width: 100% !important;
        padding: 20px;
        margin: 0;
        border: 0;
        &-heading {
          font-size: 15px;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: @mobile-landscape) {
  .landing {
    &__top {
      align-items: center;
      &-link {
        margin: 10px 5px;
      }
      &-heading,
      &-description {
        text-align: center;
      }
    }
    &__project {
      height: 600px !important;
      &-text {
        margin-bottom: 10px;
      }
    }
    &__how {
      &-items {
        &-grid {
          display: none;
        }
        &--mobile {
          display: block;
        }
        &-row {
          display: none;
        }
      }

      &-text {
        padding: 10px;
      }

      &-switch {
        &-item {
          font-size: 12px;
        }
      }
      &-item {
        &-create {
          flex: 0 0 95%;
        }
        &-invest {
          flex: 0 0 95%;
        }
      }
    }
  }
}

@media (max-width: @mobile) {
  .landing {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    &__top {
      &-heading {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      &-description {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
      }
      &-buttons {
        flex-direction: column;
        width: 100%;
        a {
          width: 100%;
        }
      }
    }

    &__today {
      &-heading {
        font-size: 13px;
        line-height: 24px;
      }
      &-content {
        margin-top: 2.5rem;
      }
      &-text {
        font-size: 14px;
        margin-bottom: 1.5rem;
      }
      &-img {
        max-width: 40px;
      }
    }

    &__help {
      margin-top: 1.5rem;
      &-title {
        text-transform: uppercase;
        font-size: 15px;
        margin-bottom: 0.5rem;
      }
      &-icon-container {
        display: none;
      }
      &-text-container {
        width: 100%;
      }
    }

    &__benefits {
      margin-top: 2rem;

      &-heading {
        font-size: 13px;
      }
      p {
        font-size: 14px;
      }
      &-image {
        max-width: 50px;
      }
      &-text {
        font-size: 14px;
      }
      &-item {
        padding: 30px 1rem;
      }
    }

    &__how {
      margin-bottom: 0;
      &-heading {
        font-size: 13px;
        margin-bottom: 1rem;
        margin-top: 4rem;
      }
      &-switch {
        margin-bottom: 30px;
      }
      &-items {
        &-grid {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@keyframes after-scroll-blink {
  0% {
    box-shadow:
      0 25px 15px 0 rgba(93, 97, 104, 0.05),
      0 16px 65px 0 rgba(23, 72, 195, 0.29);
  }
  100% {
    box-shadow:
      0 25px 15px 0 rgba(93, 97, 104, 0.05),
      0 16px 65px 0 rgba(23, 72, 195, 0.09);
  }
}
