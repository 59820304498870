@import "../_extends/vars";

.about {
  margin: 3rem 0;
  &__heading {
    .brand-font-medium();
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 1.5rem;
    p {
      margin-bottom: 1.5rem;
      margin-top: 1rem;
    }
  }

  &__social {
    padding-top: 1rem;
    ul {
      padding-left: 0;
      margin-top: 1.2rem;
    }
  }

  &__contacts {
    margin-top: 1rem;
  }

  &__content {
    padding: 2rem 0 2rem 2rem;
    background-color: #FCFCFC;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    box-shadow: 0 8px 10px 0 rgba(224,233,238,0.21);
  }

  &__link {
    color: @main-color;
    transition: all .2s ease-in-out;
    &:hover {
      color: @accent-color;
    }
  }
}

.team {
  margin-top: 2rem;
}

.team-member {
  margin-bottom: 24px;
  flex: 0 0 180px;
  margin-right: 35px;

  &__img {
    border-radius: 2px;
    width: 100%;
    height: 180px;
    object-fit: cover;
  }

  &__position {
    color: #a8b7bf;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: .8px;
  }

  &__name {
    .brand-font-medium();
    .base-transition();
    color: @main-color;
    font-size: 16px;
    line-height: 24px;
    margin-top: 1rem;
    display: block;
    &:hover {
      color: @accent-color;
    }
  }
}


@media screen and (max-width: @tablet) {
  .about {
    &__map {
      margin-top: 20px;
    }
  }
  .team-member {
    &__img {
      height: 208px;
    }
  }
}

@media screen and (max-width: @mobile) {
  .about {
    &__content {
      padding: 20px 1rem;
    }
    &__text {
      margin-bottom: 0;
      font-size: 14px;
    }

    &__contacts {
      margin-top: 0;
    }

    &__map {
      height: 272px;
      padding-right: 15px;
    }
  }

  .team-member {
    flex-basis: 100px;
    margin-left: 13px;

    &:not(:last-child) {
      margin: 0 1rem 1rem;
    }

    &__name {
      font-size: 14px;
    }

    &__img {
      height: 100px;
      width: 100px;
    }
  }
}

@media screen and (max-width: 340px) {
  .team-member {
    &:not(:last-child) {
      margin-right: .7rem;
      margin-left: .7rem;
    }
  }
}
