@import "../_extends/vars";
.footer {
  padding-top: 45px;
  padding-bottom: 60px;
  background-color: #fafafa;
  box-shadow: inset 0 2px 0 0 rgba(0, 76, 113, 0.08);
  margin-top: auto;
  &__menu {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    &-item {
      padding-right: 12px;
      &--lang {
        position: relative;
        &:hover {
          .footer__lang {
            opacity: 1;
            z-index: 1;
            visibility: visible;
          }
          &:after {
            background: none;
          }
        }
        &:after {
          position: absolute;
          right: 0;
          top: 4px;
          content: "";
          background: url("/static/img/icons/chevron-down.svg") no-repeat;
          background-size: cover;
          display: block;
          width: 16px;
          height: 16px;
          transition: all 0.2s ease-in-out;
        }
      }
    }
    &-link {
      .brand-font-medium();
      color: @main-color-light;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 12px;
      border-radius: 4px;
      margin-right: 12px;
      transition: 0.2s all ease-in-out;
      display: flex;
      align-items: center;
      gap: 3px;
      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: @accent-color;
        transform: translateY(-2px);
      }
      &:active {
        background-color: #7391db;
        color: #ffffff;
      }
    }
  }
  &__lang {
    display: flex;
    flex-direction: column;
    min-width: 110%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow:
      0 5px 15px 0 rgba(93, 97, 104, 0.05),
      0 -4px 65px 0 rgba(23, 72, 195, 0.09);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: hidden;
    &-item {
      display: flex;
      align-items: center;
      gap: 3px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.3px;
      line-height: 24px;
      color: @main-color;
      padding: 12px 25px 13px 14px;
      &:hover {
        color: @accent-color;
      }
    }
  }
  &__logo {
    margin-right: 2rem;
    position: relative;
    line-height: 1;
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: translateY(-2px);
      svg text {
        fill: @accent-color;
      }
    }
    &-testnet {
      color: @main-color;
      font-family: Courier, monospace;
      font-size: 9px;
      font-weight: bold;
      letter-spacing: 3px;
      line-height: 11px;
      text-align: center;
      border-radius: 0 0 2px 2px;
      background-color: @light-blue-color;
      position: absolute;
      width: 100%;
      bottom: -5px;
      margin: 0;
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
}

.social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &__item {
    line-height: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    transition: 0.2s all ease-in-out;
    &:hover {
      background-color: @accent-color-light;
    }
    &:active {
      background-color: #7391db;
    }
    svg {
      height: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      & > * {
        fill: @accent-color;
        transition: 0.2s all ease-in-out;
      }
    }
  }
}

@media screen and (max-width: @tablet) {
  .footer {
    &__menu {
      margin-top: 1rem;
      margin-bottom: 1rem;
      &-item {
        padding-right: 20px;
      }
      &-link {
        font-size: 14px;
      }
    }
    &__lang {
      top: 0;
      bottom: auto;
    }
  }
}

@media screen and (max-width: @mobile) {
  .footer {
    padding-top: 10px;
    padding-bottom: 20px;

    &__logo {
      order: 1;
      margin: 2rem 0 1rem;
    }

    &__content {
      flex-direction: column;
      align-items: center;
    }
    &__menu {
      flex-direction: column;
      &-item {
        font-size: 12px;
      }
      &-link {
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
  }
}
