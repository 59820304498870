@import "../_extends/vars";

.erc20-buttons {
  display: flex;
  gap: 1rem;
  margin: 40px 0;

  &__next {
    cursor: pointer;
    position: relative;
    padding: 10px 56px 12px 40px;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 32px;
      transform: translateY(-50%) rotateZ(-45deg);
      width: 7px;
      height: 7px;
      border-right: 2px solid @main-color;
      border-bottom: 2px solid @main-color;
    }
    &:hover {
      &:before {
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
      }
    }
  }
}

//success
.erc20-success {
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid #e0e9ee;
  background-color: #ffffff;
  &__container {
    text-align: center;
  }
  &__image {
    margin-top: 20px;
  }
  &__head {
    .brand-font-normal();
    color: @main-color-light;
    font-size: 25px;
    line-height: 40px;
  }
  &__text {
    color: #0c3244;
    font-size: 18px;
    letter-spacing: -0.45px;
    line-height: 2em;
    margin: 16px 0 57px;
  }
  &__button {
    margin-bottom: 100px;
    display: inline-block;
  }
}

@media (max-width: @mobile) {
  .erc20-buttons {
    flex-direction: column;
  }
}
