@import "../_extends/vars";
.info {

  &__container {
    padding: 20px 60px 30px;
  }

  &__content {
    text-align:justify;
    margin-top: 30px;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 48px;
      padding-bottom: 18px;
    }

    h3 {
      .brand-font-medium();
      font-size: 16px;
      line-height: 24px;
    }

    h4 {
      .brand-font-medium();
      padding-top: 30px;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      color: @dark-color !important;
      font-size: 16px;
      line-height: 24px;
      padding-top: 20px;
      letter-spacing: -.1px;

      strong {
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: @mobile) {
  .info {
    &__container {
      padding: 0 15px;
    }
    &__content {
      h4 {
        padding-top: 20px;
        font-size: 14px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }

  }
}
