@import "../_extends/vars";

.stat {
  background-color: #FBFBFB;
  padding: 40px 50px;
  margin: 0 -50px -40px;
  border-top: 1px solid #E0E9EE;
  &__row {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  &__block {
    flex: 0 1 385px;
    position: relative;
    padding-right: 10px;
    &-label {
      .brand-font-medium();
      font-size: 12px;
      text-transform: uppercase;
      color: @main-color-light;
      letter-spacing: .2px;
    }
    &-value {
      color: #162752;
      font-size: 25px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0;
    }
  }
  &__text {
    font-size: 20px;
    margin-bottom: 1rem;
    .brand-font-medium();
    &--fail {
      color: #e42f3a;
    }
    &--success {
      color: #28a745;
    }
    &--pre-success {
      font-size: 15px;
      color: #2896c9;
      margin-bottom: -1rem;
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
  &--landing {
    .stat__block {
      flex-basis: 200px;
    }
    .stat__text {
      font-size: 18px;
    }
    .stat__row {
      margin-bottom: 1rem;
    }
  }
}

.timer_proj {
  color: #162752;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

@media screen and (max-width: @tablet) {
  .stat {
    padding: 40px 30px;
    margin: 1rem -30px -40px;
  }
}

@media screen and (max-width: @mobile) {

  .stat {
    padding: 40px 24px;
    margin: 0 -24px -40px;
    &__row {
      flex-direction: column;
      margin-bottom: 1rem;
    }

    &__block {
      flex-basis: auto;
      margin-bottom: 2rem;

      .text-grey {
        font-size: 10px;
      }
    }

    &__buttons {
      margin-bottom: 1rem;
    }

    &__text {
      font-size: 16px;
    }
  }

}
