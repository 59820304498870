@import '../_extends/vars';

:root {
  .tooltip {
    --bs-tooltip-max-width: 300px;
    --bs-tooltip-font-size: 12px;
    .tooltip-inner {
      text-align: left;
    }
  }
}

html, body {
  .brand-font-normal();
  min-height: 100vh;
  position: relative;
  color: @main-color;
}

body {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

h3 {
  color: @main-color;
  font-size: 24px;
  font-weight: 800;
}

.content-container {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page {
  padding-top: 3rem;
  padding-bottom: 3rem;
  &__heading {
    .brand-font-medium();
    font-size: 18px;
    color: @main-color;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: @tablet) {
  .page {
    padding-bottom: 3rem;
    &__heading {
      font-size: 26px;
    }
  }
}

@media screen and (max-width: @mobile) {
  .page {
    padding-top: 1rem;
    padding-bottom: 2rem;
    &__heading {
      font-size: 18px;
      margin-bottom: 1rem !important;
    }
  }

  .button {
    font-size: 12px;
  }

  .content-container {
    padding-bottom: 3rem;
  }
}
