@import (css) '~bootstrap/dist/css/bootstrap.min.css';
@import (css) '~jquery-confirm/css/jquery-confirm.css';
@import (css) url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,800&amp;subset=cyrillic');
@import (css) 'air-datepicker/air-datepicker.css';

//EXTENDS
@import './_extends/helpers';
@import './_extends/popup';
@import './_extends/js-confirm';
@import './_extends/_extend';
@import './_extends/vars';
@import './_extends/controls';
//////////

//BLOCKS
@import './_blocks/createTabs';
@import './_blocks/uploadImage';
@import './_blocks/invest';
@import './_blocks/dialogs';
@import './_blocks/stat';
@import './_blocks/project';
@import './_blocks/share';
@import './_blocks/card';
@import './_blocks/form-params';
/////////

//BASE
@import './_base/base';
@import './_base/header';
@import './_base/footer';
@import './_base/icons';
@import './_base/blanks';
/////////

//VIEWS
@import './views/about';
@import './views/account';
@import './views/account_cnr';
@import './views/createERC20';
@import './views/createProject';
@import './views/error';
@import './views/error-404';
@import './views/faq';
@import './views/how';
@import './views/info';
@import './views/landing';
@import './views/project';
@import './views/projects';
/////////
