@import "../_extends/vars";

.dialog {
  &__radio {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &-img {
      cursor: pointer;
    }
    &:checked + img {
        outline: 2px solid #f00;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }

  &__heading {
    .brand-font-medium();
    text-align: center;
    color: @main-color-light;
    font-size: 30px;
    line-height: 40px;
  }

  &__text {
    text-align: center;
    color: @main-color;
    font-size: 16px;
    line-height: 24px;
  }

  &__image {
    object-fit: cover;
    margin: 0 auto;
    max-width: 300px !important;
    width: 100%;
    &-container {
      border: 2px solid #EFEFEF;
      border-radius: 4px;
      padding: 2rem 4rem;
    }
  }
  &__button {
    &-back {
      box-shadow: none !important;
      border-radius: 2px !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      padding: 7px 20px !important;
      text-transform: uppercase !important;
      transition: all .2s ease-in-out !important;
      letter-spacing: 0.2px;
      cursor: pointer !important;
      text-align: center !important;
    }
    &-select-provider {
      box-shadow: none !important;
      border-radius: 2px !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      padding: 7px 20px !important;
      text-transform: uppercase !important;
      transition: all .2s ease-in-out !important;
      letter-spacing: 0.2px;
      cursor: pointer !important;
      text-align: center !important;
    }
    &-ok {
      box-shadow:  none !important;
      font-size: 14px !important;
      font-weight: 600 !important;
      text-transform: uppercase !important;
      letter-spacing: 0.2px;
      cursor: pointer !important;
      text-align: center !important;
      border: 2px solid @main-color-light !important;
      &:hover {
        border-color: @accent-color !important;
      }
    }
  }
}

@media screen and (max-width: @laptop) {
  .dialog {
    &__heading {
      font-size: 20px;
      line-height: 30px;
    }
    &__image {
      width: 50%;
    }
  }
}

@media screen and (max-width: @tablet) {
  .dialog {
    &__heading {
      font-size: 18px;
      line-height: 25px;
    }
    &__image {
      width: 70%;
    }
  }
}
