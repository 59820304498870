@import "../_extends/vars";

.error-404 {
  width: 100%;
  margin-top: 8rem;
  margin-bottom: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__image {
  }
  &__heading {
    .brand-font-medium();
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: @main-color;
    margin: 1rem 0 12px;
  }
  &__text {
    color: @dark-color;
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: @tablet) {
  .error-404 {
    &__image {
      width: 20%;
    }
  }
}

@media screen and (max-width: @mobile) {
  .error-404 {
    &__image {
      width: 30%;
    }
  }
}
