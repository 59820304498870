@import "../_extends/vars";

.project {

  &.content-container {
    padding-bottom: 2rem;
  }

  &__image {
    min-height: 696px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  &__loader {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }

  &__head {
    background-color: #ffffff;
    z-index: 2;
    margin-top: -30%;
    padding: 40px 50px;
    border: 1px solid #E0E9EE;
    border-radius: 4px 4px 0 0;

    visibility: hidden;
    position: absolute;
    transform: translateY(-100%);
  }

  &__title {
    .brand-font-medium();
    font-size: 48px;
    text-transform: uppercase;
    color: @main-color-light;
    margin-top: 40px;
    word-break: break-word;
  }

  &__duration {
    .brand-font-medium();
    font-size: 12px;
    text-transform: uppercase;
    color: @main-color-light;
    letter-spacing: .2px;
  }

  &__label {
    .brand-font-medium();
    font-size: 12px;
    text-transform: uppercase;
    color: @main-color-light;
    margin-bottom: 1rem;
  }

  &__erc20 {
    display: flex;
    justify-content: flex-end;
    &-heading {
      .brand-font-medium();
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;
      color: @main-color-light;
      padding: 2px 16px;
      border-radius: 4px 0 0 4px;
      background-color: #E0E9EE;
      margin-bottom: 0;
    }
    &-address {
      .brand-font-medium();
      .base-transition();
      font-size: 12px;
      color: #99b8c7;
      line-height: 20px;
      letter-spacing: .2px;
      padding: 2px 8px;
      border: 1px solid #E0E9EE;
      border-radius: 0 4px 4px 0;
      &:hover {
        color: @accent-color
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
    &-section {

    }
    &-heading {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: .8px;
      font-weight: bold;
    }
    &-link {
      display: inline-flex;
      align-items: center;
      .brand-font-medium();
      font-size: 12px;
      line-height: 20px;
      padding: 7px 16px;
      text-transform: uppercase;
      color: @main-color-light;
      border: 1px solid #99B8C7;
      border-radius: 24px;
      .base-transition();

      &:hover {
        color: #ffffff;
        background-color: @accent-color;
      }
      &-icon {
        background-size: 16px;
        background-repeat: no-repeat;
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-left: 5px;
      }
      .icon-whitepaper {
        background-image: url("/static/img/icons/project/link.svg");
      }
      .icon-site {
        background-image: url("/static/img/icons/project/link.svg");
      }
    }
    &-social {
      display: flex;
      flex-wrap: wrap;
      &-item {
        display: block;
        margin-left: 1.8rem;
      }
    }
    &-text {
      font-weight: 600;
      font-size: 1.3rem;
      color: #ffffff;
    }
  }

  &__description {
    color: @main-color;
    word-break: break-word;
    margin-bottom: 2rem;

    img {
      max-width:100%;
      height:auto
    }

    a {
      word-break: break-word;
    }
  }

  &__progress {
    margin: 1rem 0 2.5rem;
    .progress-mark {
      z-index: 2;
    }
  }

  &__content {
    border: 1px solid #E0E9EE;
    border-top: 0;
    padding: 40px 50px;
  }

  &__command {
    margin: 1rem 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.5rem;

    &-item {
      margin-left: 10px;
      &-img {
        height: 180px;
        width: 180px;
        border-radius: 4px;
        object-fit: cover;
        object-position: top;
      }

      &-name {
        font-size: 16px;
        margin-top: 1rem;
        padding-left: 5px;
        text-align: center;
        max-width: 180px;
      }
    }
  }

  .info_project {
    .loader {
      padding-bottom: 200px;
    }
  }
}


.button_save {
  height: 48px;
  border-radius: 2px;
  background-color: @accent-color;
  border: none !important;
  color: white;
}

.products {
  margin-bottom: 50px;
  margin-top: 2rem;

  &__item {
    position: relative;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;

    &-key {
      .brand-font-medium();
      font-size: 12px;
      text-transform: uppercase;
      color: @main-color-light;
      letter-spacing: 0.2px;
    }

    &-value {
      .brand-font-medium();
      letter-spacing: 0.4px;
      font-size: 16px;
      color: @main-color;
    }

    &-img {
      max-width: 300px;
      width: 100%;
      object-fit: cover;
      height: 300px;
      border-radius: 4px;
    }

    &-heading {
      .brand-font-medium();
      text-transform: uppercase;
      margin-top: 0;
      color: @main-color;
      font-size: 20px;
      line-height: 40px;
    }

    &-description {
      margin-left: 2rem;
      font-weight: 400;
    }

    &-row {
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  &__buy {
    display: flex;

    &-count {
      display: inline-block;
      position: relative;
      vertical-align: top;
      border-radius: 4px;
      border: 2px solid @main-color;
      height: 48px;
      width: 176px;
      margin-right: 1rem;

      &-btn {
        position: absolute;
        height: 100%;
        width: 25%;
        background-color: white;
        border: none;
        box-sizing: border-box;
        font-size: 30px;
        line-height: 1.3;
        .brand-font-medium();
        color: @main-color;

        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }

      &-value {
        display: inline-block;
        position: absolute;
        left: 25%;
        width: 50%;
        height: 100%;
        color: @main-color;
        font-size: 20px;
        .brand-font-medium();
        letter-spacing: -0.2px;
        line-height: 48px;
        text-align: center;
        border-right: 2px solid @main-color;
        border-left: 2px solid @main-color;
      }
    }
  }
}

.team-members {
  &__heading {
    color: @main-color;
    font-size: 32px;
    font-weight: bold;
    line-height: 48px;
    margin: 40px 0 32px;
  }
}

.team-member {
  &__photo {
    border-radius: 2px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    object-position: top;
  }

  &__name {
    color: @main-color;
    font-size: 16px;
  }
}

.section-more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .text-grey();
  &__icon {
    margin-left: 0.5rem;
  }
  &:hover {
    color: @accent-color;
  }
}

@media screen and (min-width: 1900px) {
  .project__head {
    margin-top: -25%;
  }
}


@media screen and (min-width: 2100px) {
  .project__head {
    margin-top: -18%;
  }
}

@media screen and (max-width: 1024px) {
  .project__image {
    min-height: 450px;
  }
}

@media screen and (max-width: @tablet) {
  .project {
    &__info {
      flex-direction: column;
    }

    &__image {
      min-height: 400px;
    }

    &__title {
      font-size: 38px;
    }

    &__head {
      padding: 40px 30px;
    }

    &__content {
      padding: 40px 30px;
    }
  }

  .team-member {
    &__name {
      margin-top: 5px;
    }
  }

  .products {
    &__item {
      &-img {
        max-width: 200px;
        height: 200px;
      }
    }
  }
}

@media screen and (max-width: @mobile) {
  .project {
    &__image {
      min-height: 160px;
    }

    &__head {
      margin-top: 0;
      padding: 1rem 1.5rem;
    }

    &__description {
      font-size: 14px;
    }

    &__title {
      font-size: 20px;
    }

    &__content {
      padding: 40px 24px;
    }

    &__duration {
      font-size: 0.8rem;
    }

    &__info {
      margin-top: 1rem;
      margin-bottom: 0;
      &-section {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
      }
      &-link {
        width: 100%;
        justify-content: center;
        font-size: 12px;
        padding: 1rem;
        margin-bottom: 10px;
      }
      &-text {
        font-size: .8rem;
      }
      &-social {
        &-item {
          margin-right: 1.8rem;
          margin-left: 0;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__erc20 {
      flex-direction: column;
      margin-top: 1rem;
      &-heading {
        text-align: center;
      }
      &-address {
        font-size: 10px;
        font-weight: 400;
        overflow: hidden;
        text-align: center;
      }
    }

    &__command {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      &-item {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &-img {
          height: 100px;
          width: 100px;
          margin-right: 1rem;
        }
        &-name {
          .brand-font-medium();
          font-size: 16px;
          max-width: unset;
        }
      }
    }
  }

  .team-member {
    &__photo {
      height: 160px;
    }
  }

  .products {
    &__item {
      &-heading {
        line-height: 26px;
      }
      &-img {
        max-width: 100px;
        height: 100px;
      }
      &-description {
        margin-left: 1rem;
      }
      &-value {
        font-size: 14px;
      }
      &-row {
        line-height: 18px;
      }
    }

    &__buy {
      margin-left: -7rem;
    }
  }
}
