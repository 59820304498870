@import "../_extends/vars";
@import "../_extends/account_header";

.account {
  &__body {
    padding-bottom: 6rem;
  }
  &__empty {
    text-align: center;
  }
  &__project {
    margin-bottom: 1.5rem;
    &-head {
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #EFEFEF;
      border-bottom: 1px solid rgba(0,0,0,0.2);
      padding: 5px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-heading {
      color: @dark-color;
      font-weight: bold;
      font-size: 1.3rem;
      margin: 1rem 0;
    }
    &-caret {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 255 255'%3E%3Cpolygon points='0 63.8 127.5 191.3 255 63.8 ' fill='%23909090'/%3E%3C/svg%3E");
      background-size: cover;
      width: 18px;
      height: 18px;
      cursor: pointer;
      transition: transform .2s ease-in-out;
      &.opened {
        transform: rotate(180deg);
      }
    }

    &-body {
      background: #fafafa;
      padding: 20px 20px 25px;
      border: 1px solid #e8e8e8;
      border-top: 0;
    }
    &-poster {
      border-radius: 4px;
    }
    &-ico {
      max-width: 280px;
    }
    &-label {
      font-weight: 600;
      color: #212121;
      margin-bottom: 10px;
    }

    .blue {
      color: @dark-blue-color;
      font-weight: normal;
    }

    .red {
      color: #E42F3A;
      font-weight: normal;
    }

    .bold {
      font-weight: bold;
    }

    &-bordered-block {
      margin: 10px 0 0 0;

      border: 1px solid rgba(158, 155, 155, 0.68);
      border-radius: 3px;
      padding: 5px;
    }

    &-status-section {
      margin: 10px 0 20px 0;

      border: 1px dashed rgba(158, 155, 155, 0.68);
      border-radius: 3px;
      padding: 5px;
    }

    &-info-section {
      margin: 10px 0 20px 0;

      border: 1px dashed rgba(158, 155, 155, 0.68);
      border-radius: 3px;
      padding: 5px;
    }

    &-block {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }

      .main-btn {
        width: 100%;
        margin-top: 36px;
      }
    }

    &-withdraw {
      &-input {
        color: #212121;
        margin-bottom: 8px;
        width: 100%;
        border: 1px solid rgba(0,0,0,0.06);
        border-radius: 2px;
        padding: 10px;
      }
      &-btn {
        border-radius: 4px;
        width: 100%;
        padding: 10px;
      }
    }

    &-text {
      opacity: 0.5;
      color: @dark-color;
      font-size: 16px;
      line-height: 24px;
    }

  }
}


.input-block {
  background: #f0f0f0;
  padding: 7px;
  position: relative;
  &__value {
    font-weight: 600;
    overflow: auto;
  }
  &__link {
    color: @main-color;
    &:hover {
      color: @accent-color;
    }
  }
  &__currency {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.text-label-grey {
  font-size: .8rem;
  font-weight: bold;
  color: #cbcbcb;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// вынести в _blocks
.timer {
  display: flex;
  justify-content: space-between;
  &__block {
    margin-right: 7px;
    text-align: center;
    &-value {
      width: 64px;
      background: #f0f0f0;
      padding: 7px 10px;
      text-align: center;
      font-weight: 600;
      color: #212121;
      margin-bottom: 7px;
    }
    &-label {
    }
  }
}

.investment-block {
  &__label {
    margin: 0 0 0 10px;
    padding-bottom: 0;
  }

  &__field {
    color: #CBCBCB;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-transform: uppercase;
    margin: 8px 0;
    padding: 0 0 0 10px;
  }

  &__empty {
    border: 1px solid rgba(0, 0, 0, 0.06);

    &-label {
      opacity: 0.5;
      color: #212121;
      font-size: 16px;
      line-height: 24px;
      margin: 14px 13px;
    }
  }

  &__list {
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    padding: 2px;
    max-height: 184px;
    overflow-y: auto;
    overflow-x: hidden;
    &-item {
      display: block;
      background-color: #FFFFFF;
      color: #212121;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 2px solid #F0F0F0;
      padding: 5px;

    }
  }
}

.withdrawal {
  margin-top: 20px;

  .button {
    margin-top: 10px;
    width: 100%;
  }

  p
  {
    font-size: 12px;
    margin-top: 3px;
    color: #a0a0a0;
  }

  span a
  {
    color: black;
    text-decoration: underline !important;
  }
}

@media (min-width: @tablet) {
  .account__body {
    min-height: calc(100vh - 239px - 157px); // высота шапки + футера
  }
}

@media (min-width: 960px) {
  .account__body {
    min-height: calc(100vh - 252px - 157px); // высота шапки + футера
  }
}

@media (min-width: @laptop) {
  .account__body {
    min-height: calc(100vh - 227px - 155px); // высота шапки + футера
  }
}


//@media (max-width: @laptop) {
//  .timer__block {
//    &-label {
//      font-size: 10px;
//      .brand-font-medium();
//    }
//    &-value {
//      padding: 7px 5px;
//      width: 50px;
//    }
//  }
//}


@media (max-width: @tablet) {
  .account__project {
    &-heading {
      font-size: 1rem;
      .brand-font-medium();
    }
    &-block:last-child {
      margin-bottom: 15px;
    }
    &-poster {
      margin-bottom: 1rem;
      max-height: 200px;
    }
  }
}

@media (max-width: @mobile) {
  .account__project {
    &-body {
      font-size: 12px;
    }

    &-head {
      padding: 5px 15px;
    }

    &-caret {
      width: 12px;
      height: 12px;
    }

    &-block {
      margin-bottom: 1rem;
    }
  }

  .account__body {
    margin-bottom: 0;
  }

  .investment-block__field {
    .brand-font-medium();
  }

}
