@import "../_extends/vars";


.my-popup-title {
 .jconfirm-title {
   color: @main-color;
   font-size: 20px !important;
   font-weight: bold;
 }
}

.jconfirm .jconfirm-box {
  padding: 0 !important;
  .jconfirm-content {
    padding: 20px 40px;
  }
}

.jconfirm .jconfirm-box .jconfirm-buttons {
  padding: 0 40px 20px;
  .button {
    padding: 15px 40px !important;
    border: 2px solid;
    font-weight: 600 !important;
  }
}

.jconfirm .jconfirm-box div.jconfirm-title-c {
  background-color: #F6F6F7;
  padding: 25px 40px 25px !important;
}

.input-currency-placeholder {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #B2B2B2;
  text-align: right;
}

.input-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 4px 4px 0 0;
  border: 2px solid #ececec;
  &:first-child {
    border-bottom: 0;
  }

  &__label {
    color: #a7abc2;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    min-width: 152px;
    padding: 16px;
    margin-bottom: 0;
  }

  &__input {
    color: @dark-color;
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    border: none;
    outline: none;
    line-height: 1.2;
    flex: 1 1 300px;
    padding-right: 1rem;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      .input-currency-placeholder();
    }
    &::-moz-placeholder { /* Firefox 19+ */
      .input-currency-placeholder();
    }
    &:-ms-input-placeholder { /* IE 10+ */
      .input-currency-placeholder();
    }
  }

  &__currency {
    min-width: 124px;
    color: @dark-color;
    font-size: 16px;
    font-weight: bold;
    padding: 22px 25px 22px 30px;
    background-color: rgba(63,81,181,0.08);
  }
}

.hint {
  color: @grey-color;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 2rem;
  display: inline-block;
  &__content {
    color: #323439;
    font-size: 12px;
    line-height: 16px;
    margin-top: 34px;
    border-radius: 2px;
    background-color: rgba(241,95,121,0.06);
    padding: 24px 25px;

    span {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: @tablet) {
  .jconfirm .jconfirm-box .jconfirm-buttons {
    padding: 0 10px 20px;
    .button {
      padding: 15px 20px !important;
    }
  }
}

@media screen and (max-width: @mobile) {
  .jconfirm .jconfirm-box .jconfirm-buttons {
    padding: 0 20px 10px;

    .button {
      padding: 15px 20px !important;
    }
  }

  .jconfirm .jconfirm-box div.jconfirm-title-c {
    padding: 15px 20px 15px !important;
    font-size: 18px;
  }
}
