@import "../_extends/vars";

.header {
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  box-shadow: inset 0 -2px 0 0 rgba(0, 76, 113, 0.08);
  &--float-info-window {
    background-color: #6afc16;
    position: fixed;
    top: 200px;
    right: 10%;
    width: 20%;
    padding: 20px;
    border-radius: 10px;
    opacity: 1;
    z-index: 1000;
  }
  &--landing {
    padding-top: 40px;
    box-shadow: none;
  }
  &__logo {
    margin-right: 2.5rem;
    line-height: 1;
    &-link {
      display: inline-block;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: translateY(-2px);
        svg text {
          fill: @accent-color;
        }
      }
    }
    &-testnet {
      color: #ffffff;
      font-family: Courier, serif;
      font-size: 9px;
      font-weight: bold;
      letter-spacing: 3px;
      line-height: 11px;
      text-align: center;
      border-radius: 0 0 2px 2px;
      background-color: @accent-color;
      position: absolute;
      width: 100%;
      bottom: -5px;
      margin: 0;
    }
    &-img {
      width: 81px;
      height: 42px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__nav {
    display: flex;
    align-items: center;
    &-link {
      .brand-font-medium();

      color: @main-color;
      cursor: pointer;
      font-size: 12px;
      line-height: 24px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 1px 12px;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: @accent-color;
        transform: translateY(-2px);
      }
      &:active {
        background-color: #c1d0f5;
      }

      &--account {
        display: flex;
        border-radius: 4px;
        border: 2px solid #004C71;
        transition: border 0.2s ease-in-out;
        &:hover {
          border-color: transparent;
        }
        a {
          color: @main-color;
          display: inline-block;
        }
        button {
          padding: 10px 16px;
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }

      &--lang {
        & + .dropdown-menu {
          border-radius: 4px;
          border: 0;
          box-shadow:
            0 5px 15px 0 rgba(93, 97, 104, 0.05),
            0 -4px 65px 0 rgba(23, 72, 195, 0.09);

          .dropdown-item {
            color: @main-color;
            font-weight: 600;
            padding-top: 7px;
            padding-bottom: 7px;
            &:active {
              background-color: @accent-color;
              color: #fff;
            }
          }
        }
        &::after {
          border-top-color: @main-color;
        }
        &.show {
          &::after {
            border-top-color: #fff;
          }
        }
      }
    }
    &--right {
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__button {
    border-radius: 4px !important;
    font-weight: bold !important;
    text-transform: none !important;
  }

  &__close {
    .brand-font-medium();
    font-size: 12px;
    margin-top: 2rem;
    color: @main-color;
    display: flex;
    align-items: center;
    &-icon {
      background: url("/static/img/close-icon.svg") no-repeat;
      background-size: cover;
      width: 12px;
      height: 12px;
      margin-left: 5px;
    }
  }

  &__btn-group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.cd-primary-nav-trigger {
  display: none;
}

.top-bg {
  background: url("/static/img/landing/background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

@media screen and (max-width: @laptop) {
  .header {
    &--float-info-window {
      background-color: #6afc16;
      position: fixed;
      top: 10%;
      right: 5%;
      left: 5%;
      width: 90%;
      padding: 20px;
      border-radius: 10px;
      opacity: 1;
      z-index: 1000;
    }

    &__nav {
      display: none;

      &--mobile {
        flex-direction: column;
        display: flex;
        align-items: center;
      }
      &-link {
        font-size: 20px;
        margin: 1.2rem 0;

        &:not(:last-child) {
          margin-right: 0;
        }
      }

      &-label {
        color: #ffffff;
        margin: 1.2rem 0;
      }

      &-secondary {
        display: none;
      }
    }
    &__button {
      font-size: 20px !important;
    }
  }

  .cd-primary-nav-trigger {
    display: block;
  }
}

/*-----------------
    Mobile menu
 -----------------*/

.cd-header {
  transition: background-color 0.3s;
}

.cd-header.is-fixed {
  /* when the user scrolls down, we hide the header right above the viewport */
  position: fixed;
  top: -80px;
  background-color: rgba(2, 23, 37, 0.96);
  transition: transform 0.3s;
}

.cd-header.is-visible {
  /* if the user changes the scrolling direction, we show the header */
  transform: translate3d(0, 100%, 0);
}

.cd-primary-nav-trigger {
  position: absolute;
  z-index: 12;
  right: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  width: 50px;
  height: 30px;
  background-color: transparent;
}

.cd-primary-nav-trigger .cd-menu-icon {
  /* this span is the central line of the menu icon */
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  width: 24px;
  height: 2px;
  background-color: @accent-color;
  transition: background-color 0.3s;
  /* these are the upper and lower lines in the menu icon */
}

.cd-primary-nav-trigger .cd-menu-icon::before,
.cd-primary-nav-trigger .cd-menu-icon:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: @accent-color;
  right: 0;
  transition:
    transform 0.3s,
    top 0.3s,
    background-color 0s;
}

.cd-primary-nav-trigger .cd-menu-icon::before {
  top: -7px;
}

.cd-primary-nav-trigger .cd-menu-icon::after {
  top: 7px;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before,
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  background-color: @accent-color;
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
  top: 0;
  transform: rotate(135deg);
}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  top: 0;
  transform: rotate(225deg);
}

.cd-primary-nav {
  /* by default it's hidden - on top of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 11;
  text-align: center;
  padding: 80px 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  transform: translateY(-100%);
  transition-property: transform;
  transition-duration: 0.4s;
}

.cd-primary-nav.is-visible {
  transform: translateY(0);
}

.header.menu-is-open {
  background-color: transparent;
}

@media (min-width: @tablet) {
  .cd-primary-nav-trigger {
    width: 30px;
    background-color: transparent;
    height: 30px;
    line-height: 30px;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }

  .cd-primary-nav-trigger .cd-menu-icon {
    left: auto;
    transform: translateX(0) translateY(-50%);
  }
}

@media (max-width: @tablet) {
  .top-bg {
    min-height: auto;
  }
}

@media (max-width: @mobile) {
  .header {
    box-shadow: none;
    &--landing {
      padding-top: 25px;
    }
    &__logo {
      &-img {
        width: 66px;
        height: 33px;
      }
    }
    &__btn-group {
      display: flex;
      flex-direction: column;
      width: 100%;

      .button {
        margin-bottom: 1rem;
      }
    }

    &__nav {
      &--mobile {
        padding: 0 1rem;
      }
    }
  }

  .cd-primary-nav {
    padding: 40px 0;
  }

  .cd-primary-nav-trigger {
    right: 7px;
  }
}
