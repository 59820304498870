@import "../_extends/vars";

.card-list {
  padding-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  &__item {
    margin-bottom: 40px;
    border-radius: 4px;
    background-color: #fcfcfc;
    border: 1px solid #eaeaea;
    box-shadow: 0 8px 10px 0 rgba(224, 233, 238, 0.21);
    padding: 40px 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: none;
    flex: 1;

    &--before-load {
      box-shadow:
        0 25px 15px 0 rgba(93, 97, 104, 0.05),
        0 16px 65px 0 rgba(23, 72, 195, 0.29);
    }

    &--loaded {
      animation-name: after-scroll-blink;
      animation-duration: 1s;
      animation-delay: 0s;
      animation-timing-function: ease-in;
    }

    &-heading {
      .brand-font-medium();
      font-size: 16px;
      text-transform: uppercase;
      line-height: 24px;
      text-align: center;
      color: @main-color;
      margin-bottom: 38px;
    }

    &-content {
      padding: 0 10px 0 20px;
    }

    &-text {
      font-size: 14px;
      letter-spacing: -0.3px;
      line-height: 1.5;
      color: #162752;
      margin-left: 28px;
      margin-bottom: 20px;
      position: relative;
    }

    &-check-mark {
      height: 22px;
      width: 22px;
      border: 2px solid #00b8c0;
      background-color: rgba(23, 72, 195, 0);
      border-radius: 50%;
      position: absolute;
      left: -34px;

      &:before {
        content: "";
        width: 5px;
        height: 10px;
        border-bottom: 2px solid #00b8c0;
        border-right: 2px solid #00b8c0;
        transform: translate(-50%, -50%) rotateZ(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }

    &-icon {
      background: url("/static/img/icons/star.svg") no-repeat;
      background-size: contain;
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: -34px;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-price {
      font-size: 52px;
      font-weight: 600;
      letter-spacing: -0.52px;
      line-height: 24px;
      text-align: center;
      color: @main-color-light;
      margin: 30px 0 0;
      display: inline-block;
    }

    &-eth {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.16px;
      line-height: 24px;
      color: @main-color-light;
      margin: 0;
      display: inline-block;
      text-transform: uppercase;
    }

    &-currency {
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
      letter-spacing: -0.16px;
      line-height: 24px;
      text-align: center;
      color: @main-color-light;
      margin-bottom: 1em;
    }
  }
}

@media (max-width: @laptop) {
  .card-list {
    grid-template-columns: 1fr 1fr;
    &__item {
      padding: 40px 15px 30px;
    }
  }
}

@media (max-width: @tablet) {
  .card-list {
    grid-template-columns: auto;
    &__item {
      padding: 20px;
      margin-bottom: 1rem;
      &-text {
        margin-left: 20%;
      }
      &-heading {
        font-size: 14px;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (max-width: @mobile) {
  .card-list {
    &__item {
      &-bottom {
        .button {
          width: 100%;
        }
      }
    }
  }
}
