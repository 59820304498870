@import "vars";

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.cover-img {
  object-fit: cover;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

.vertical-align {
  display: flex;
  align-items: center;
}
.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.text-grey {
  .brand-font-medium();
  color: @light-grey;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.wrong-value {
  color: red !important;
}

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 250px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: left;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/* LOADER 2 */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  &--text {
    width: 230px;
    height: auto;
    text-align: center;
  }
  &-text {
    font-size: 12px;
    text-align: center;
  }
}

.loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: @accent-color;
  margin: 30px 5px;
}

.loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
