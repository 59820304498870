@import "../_extends/vars";

.form-params {
  &__section {
    padding: 40px 30px;
    box-shadow: inset 0 -1px 0 0 rgba(144, 144, 144, 0.24);
  }
  &__title {
    display: flex;
    align-items: center;
    &-text {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 17px;
      text-transform: uppercase;
      color: @grey-color;
      margin: 0;
    }
  }
  &__item {
    &-label {
      font-size: 16px;
      letter-spacing: -0.1px;
      line-height: 22px;
      color: @main-color;
      margin-bottom: 0;
      margin-right: 2px;
    }
    img {
      position: relative;
      top: -2px;
    }
    &-input {
      border: 1px solid rgba(144, 144, 144, 0.24);
      border-radius: 4px;
      background-color: #ffffff;
      letter-spacing: -0.1px;
      font-weight: 600;
      line-height: 22px;
      color: @main-color;
      margin-top: 1.5rem;
      width: 100%;
      padding: 10px 0 10px 12px;
      &:hover,
      &:focus,
      &:active,
      &:disabled,
      &[readonly] {
        border: 1px solid rgba(144, 144, 144, 0.24);
        border-radius: 4px !important;
        background-color: #ffffff;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 22px;
        color: @main-color;
      }
      &-text {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(-9px);
        font-size: 16px;
        letter-spacing: -0.1px;
        line-height: 22px;
        color: @grey-color;
        z-index: 5;
      }
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
      }
    }
    &-text {
      font-size: 12px;
      letter-spacing: -0.2px;
      line-height: 17px;
      color: @main-color;
    }
  }
}

@media (max-width: @mobile) {
  .form-params {
    &__section {
      padding: 30px 0;
    }
    &__item {
      &-label {
        font-size: 14px;
      }
    }
  }
}
